/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    ul: "ul",
    li: "li",
    div: "div",
    h2: "h2",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "CDN(Content Delivery Network)についてまとめておく"), "\n", React.createElement(_components.p, null, "CDNとはコンテンツの配信を高速にするための分散サーバシステムのことをいう"), "\n", React.createElement(_components.p, null, "ここでいうコンテンツとは web 上でやり取りされるファイル(html, image…)などのことを指している"), "\n", React.createElement(_components.p, null, "高速化を行うため"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "ユーザの物理的な位置に基づいて、速く配信できるであろうサーバからコンテンツを送る"), "\n", React.createElement(_components.li, null, "CDN 上にあるサーバはオリジンサーバ（コンテンツの配信元）から最新の状態をキャッシュしておき、リクエストがあれば、キャッシュしたコンテンツを配信する"), "\n", React.createElement(_components.li, null, "CDN 上のサーバ同士でキャッシュしたコンテンツを同期して、オリジンサーバへのリクエストを減らす"), "\n"), "\n", React.createElement(_components.p, null, "というようなことをしているらしい"), "\n", React.createElement(_components.p, null, "分散システムになっているので、負荷分散を行うこともでき、障害に強い"), "\n", React.createElement(_components.p, null, "web ページの読み込みの遅延や、表示の高速化に役立つ"), "\n", React.createElement(_components.p, null, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 600px; \"\n    >\n      <a\n    class=\"gatsby-resp-image-link\"\n    href=\"/static/10662e49c5964cc088604479b30a58da/5a190/cdn.png\"\n    style=\"display: block\"\n    target=\"_blank\"\n    rel=\"noopener\"\n  >\n    <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 29.333333333333332%; position: relative; bottom: 0; left: 0; background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAYAAADDl76dAAAACXBIWXMAAAsTAAALEwEAmpwYAAABMElEQVQY022Qu0oDQRSG9yVtAnmAvIBlCisrSZk2bpUiYKcgIY0ga5UYyAW2CLK7WbMke7/v7CczEDHqDz8zh/nPOR+jXT/CZnvAu7tBpDFSbdty1s+7lBDiov79rl3dC/TXjO3DE3NjjqBVIelzs+M4rFYrfN//HlKWJXXdqLppGuq6Vqd2+1wgWoG5/eTt5V3GLzZalsVgMKDf7zMajUjTlDzPMQyDxeKdJEnUIrngeDyibeyELE2Jo5AgDAiCkCDw8TxPDZxMJnQ6HXq9Ht1ul/V6rQht2+Z0OhHFMfv9nizL2O12aK79gWXZ2I5DHMeKSNo0TUWzXC4ZDofous54PMZ1XUUjM1EUK8LZbMZ0OlXWyrKiKAqqqvrzwVJFnuMdDopCZv5TmiSKNgxDvgAwlrtAgGVMLAAAAABJRU5ErkJggg=='); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"CDNの概念図\"\n        title=\"CDNの概念図\"\n        src=\"/static/10662e49c5964cc088604479b30a58da/0a47e/cdn.png\"\n        srcset=\"/static/10662e49c5964cc088604479b30a58da/8a4e8/cdn.png 150w,\n/static/10662e49c5964cc088604479b30a58da/5a46d/cdn.png 300w,\n/static/10662e49c5964cc088604479b30a58da/0a47e/cdn.png 600w,\n/static/10662e49c5964cc088604479b30a58da/5a190/cdn.png 800w\"\n        sizes=\"(max-width: 600px) 100vw, 600px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n  </a>\n    </span>"
    }
  })), "\n", React.createElement(_components.h2, {
    id: "参考",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#%E5%8F%82%E8%80%83",
    "aria-label": "参考 permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "参考"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://blog.redbox.ne.jp/what-is-cdn.html"
  }, "第１回 ＣＤＮ の 仕組み （CDN はどんな技術で何が出来るのか） | REDBOX Labo")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.webopedia.com/TERM/C/CDN.html"
  }, "What is CDN - Content Delivery Network? Webopedia Definition")), "\n", React.createElement(_components.li, null, "『サーバ/インフラを支える技術』"), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
